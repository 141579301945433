<template>
  <div class="common-wrapper">
    <!-- 预警信息 -->
    <el-row :gutter="4" class="elRow" style="display: flex">
      <el-col :span="3">
        <el-date-picker
          :clearable="false"
          style="width: 100%"
          size="small"
          v-model="formInline.optionDate1"
          type="date"
          :placeholder="$t('SecurityMonitor.WarningInfo.Placeholder')"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions0"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="0.5">
        <span style="line-height: 30px; color: #dcdfe6">~</span>
      </el-col>
      <el-col :span="3">
        <el-date-picker
          :clearable="false"
          style="width: 100%"
          size="small"
          v-model="formInline.optionDate2"
          type="date"
          :placeholder="$t('SecurityMonitor.WarningInfo.Placeholder')"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions1"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="3">
        <el-select
          style="width: 100%"
          v-model="formInline.devicValue"
          :placeholder="$t('SecurityMonitor.WarningInfo.WarningPlaceholder')"
          size="small"
          class="placeOption"
        >
          <el-option
            v-for="item in devNameData"
            :key="item.devicValue"
            :label="
              $t(`SecurityMonitor.WarningInfo.SelectOptions.${item.label}`)
            "
            :value="item.devicValue"
          >
          </el-option>
        </el-select>
      </el-col>
      <!-- <el-col :span="3">
          <el-input style="width: 97%" size="small" v-model.trim="formInline.keywords" :placeholder="`请输入${placeholder}`" @keyup.enter.native="onSubmit('ser')"></el-input>
        </el-col> -->
      <el-col :span="3">
        <el-button size="small" type="primary" @click="onSubmit('ser')">{{
          $t("public.Inquire")
        }}</el-button>
        <el-button style="margin-left:4px" size="small" type="primary" @click="outreport">{{
          $t("public.Derive")
        }}</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="4" class="elRow">
      <el-table
        size="mini"
        ref="myTable"
        :data="lists"
        fit
        tooltip-effect="dark"
        class="tableClass"
        stripe
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }"
        :default-sort="{ prop: 'createTime', order: 'descending' }"
        @sort-change="sort"
      >
        <el-table-column
          fixed
          width="160"
          :label="$t('SecurityMonitor.WarningInfo.serviceType')"
          prop="serviceType"
          :show-overflow-tooltip="true"
        >
          <template v-slot="{ row }">
            <!-- <template v-if="row.serviceType == 'gateway'">
              {{ $t("SecurityMonitor.WarningInfo.Gateway") }}
            </template> -->
              {{ row.serviceType }}
          </template>
        </el-table-column>
        <el-table-column
          fixed
          width="160"
          :label="$t('SecurityMonitor.WarningInfo.ServiceAddress')"
          prop="serviceAddress"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          width="160"
          :label="$t('SecurityMonitor.WarningInfo.ServicePort')"
          prop="servicePort"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          :label="$t('SecurityMonitor.WarningInfo.WarningPlaceholder')"
          width="160"
          prop="warningType"
          :show-overflow-tooltip="true"
        >
          <template v-slot="{ row }">
            <template v-if="row.warningType == '2'">
              {{ $t("SecurityMonitor.WarningInfo.Memory") }}
            </template>
            <template v-if="row.warningType == '1'"> CPU </template>
            <template v-if="row.warningType == '3'">
              {{ $t("SecurityMonitor.WarningInfo.HardDisk") }}
            </template>
            <template v-if="row.warningType == '5'">
              {{ $t("SecurityMonitor.WarningInfo.ServiceException") }}
            </template>
            <!-- <template v-if="row.warningType == '4'">
                    管理服务异常
                </template>
                <template v-if="row.warningType == '5'">
                    网关服务异常
                </template> -->
          </template>
        </el-table-column>
        <el-table-column
          width="160"
          :show-overflow-tooltip="true"
          :label="$t('SecurityMonitor.WarningInfo.WarningContent')"
          prop="warningContent"
        >
        </el-table-column>
        <el-table-column
          width="160"
          :show-overflow-tooltip="true"
          :label="$t('SecurityMonitor.WarningInfo.WarningTime')"
          prop="warningTime"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          :label="$t('public.ReportingTime')"
          prop="createTime"
        >
        </el-table-column>
        <!-- <el-table-column fixed="right" label="操作">
            <template v-slot={row}>
              <span size="small" @click="details(row)" style="color: #d38a08;cursor: pointer;">详情</span>
            </template>
          </el-table-column> -->
      </el-table>
      <!-- 分页 -->
      <pagination
        :page="page.currentPage"
        :limit="page.pageSize"
        :total="page.total"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetList"
      ></pagination>
    </el-row>
    <!-- 详情弹框 -->
    <!-- <Eldialog @handleClose="handleClose" :title="'详情'" :dialogVisible="outerVisible" :flagbtn="false" @determine="handleClose" :num="'470px'">
        <VirusDetail :detailData="detailsArray"></VirusDetail>
      </Eldialog> -->
  </div>
</template>
<script>
import Eldialog from "@/components/elDialog/index.vue";
import pagination from "../../components/pagination/page";
export default {
  components: { pagination, Eldialog },
  data() {
    return {
      groupFullNameShowByIndex: "", //用户部门截取的下标
      pickerOptions1: {},
      pickerOptions0: {},
      tradeSelections1: [
        "serviceType",
        "serviceAddress",
        "servicePort",
        "warningTypeStr",
        "warningContent",
        "warningTime",
        "createTime",
      ],
      page: {
        pageSize: 10, //每页条数,  默认10条
        total: 0, //总条数
        currentPage: 1,
        orderColume: "create_time", //排序字段
        orderRule: "DESC", //排序规则ASC、DESC
      },
      formInline: {
        optionDate1: "",
        optionDate2: "",
        devicValue: "",
        keywords: "",
      },
      placeholder: this.$t("SecurityMonitor.WarningInfo.WarningPlaceholder"),
      devNameData: [
        {
          devicValue: "",
          label: "预警类型",
        },
        {
          devicValue: "1",
          label: "CPU",
        },
        {
          devicValue: "2",
          label: "内存",
        },
        {
          devicValue: "3",
          label: "硬盘",
        },
        {
          devicValue: "5",
          label: "服务异常",
        },
        // {
        //  devicValue: '4',
        //  label: '管理服务异常'
        //},
        //{
        // devicValue: '5',
        //  label: '网关服务异常'
        //},
      ],
      seleShift: "device_name", // 选中下拉框转译
      key: 1, //table  key
      checkList: [], //选中的选项eng数组
      formThead: [], //渲染表头
      number: 0,
      lists: [],
      detailsArray: [], //详情
      outerVisible: false, // 详情弹框
      flag: 1,
      cityarrs: [],
    };
  },
  watch: {},
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.getRunInfoList();

    if (this.$route.query.name) {
      this.MingTime();
      this.getRunInfoList();
    }
  },
  updated() {
    // myTable是表格的ref属性值
    if (this.$refs.myTable && this.$refs.myTable.doLayout) {
      this.$refs.myTable.doLayout();
    }
  },
  methods: {
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    //下拉框
    changeSele(item) {
      // console.log(item, "下拉框");
      this.formInline.devicValue = item;
      this.devNameData.forEach((k) => {
        if (item == k.label) {
          this.seleShift = k.devicValue;
        }
      });
      // console.log(this.seleShift,"this.seleShift");
      this.placeholder = item;
    },
    // 详情关闭按钮
    handleClose() {
      this.outerVisible = false;
    },
    // 排序@sort-change="sort"
    sort(column) {
      // console.log(column.order, column.prop);
      if (column.order == "ascending") {
        this.page.orderRule = "ASC";
      } else if (column.order == "descending") {
        this.page.orderRule = "DESC";
      }
      this.page.orderColume = column.prop;
      this.onSubmit(); //再次调用查询接口
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page;
      this.page.pageSize = limit;
      // 调用查询方法
      this.onSubmit();
    },

    changeDate() {
      let start = new Date(this.formInline.optionDate1).getTime();
      let end = new Date(this.formInline.optionDate2).getTime();
      this.pickerOptions0 = {
        disabledDate: (time) => {
          if (end != "") {
            return time.getTime() > Date.now() || time.getTime() > end;
          }
          return time.getTime() > Date.now();
        },
      };
      this.pickerOptions1 = {
        disabledDate: (time) => {
          return (
            time.getTime() < start - 24 * 60 * 60 * 1000 ||
            time.getTime() > Date.now()
          );
        },
      };
    },

    //获取列表
    async getRunInfoList() {
      var getAnyMonth = function (symbol, n) {
        var symbol = symbol || "-";
        var nowDate = new Date();
        nowDate.setMonth(nowDate.getMonth() + n);
        var year = nowDate.getFullYear();
        var month = nowDate.getMonth() + 1;
        var month = month < 10 ? "0" + month : month;
        var day = nowDate.getDate();
        var day = day < 10 ? "0" + day : day;
        return year + symbol + month + symbol + day;
      };
      var getDate = function () {
        var date = new Date();
        var year = date.getFullYear();
        var mon = date.getMonth() + 1;
        var day = date.getDate();
        return (
          year +
          "-" +
          (mon < 10 ? "0" + mon : mon) +
          "-" +
          (day < 10 ? "0" + day : day)
        );
      };

      if (this.$route.query.fl == true) {
        this.Day();
      } else if (this.$route.query.fl == false) {
        this.Mounth();
      } else if (this.$route.query.card == true) {
        this.card();
      } else if (this.$route.query.cli == true) {
        this.MingTime();
      } else {
        this.formInline.optionDate1 = getAnyMonth("-", -1);
        this.formInline.optionDate2 = getDate();
      }

      this.changeDate();

      var parmas = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        startTime: this.formInline.optionDate1,
        endTime: this.formInline.optionDate2,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        warningType: this.formInline.devicValue,
      };
      const res = await this.$axios.post(
        "/httpServe/alarm/getDataInfo",
        parmas,
        true
      );
      console.log(res, "预警信息===========");
      this.lists = res.data.content;
      // 对于为空的字段，显示N/A
      for (var i = 0; i < this.lists.length; i++) {
        var obj2 = this.lists[i];
        // console.log(obj2, "obj2");|| obj2[key] == ""
        for (var key in obj2) {
          if (obj2[key] == null) {
            obj2[key] = "N/A";
          }
        }
      }
      this.page.total = res.data.total;
    },
    //查询
    async onSubmit(flag) {
      if (flag == "ser") {
        this.page.currentPage = 1;
      }
      if (this.$route.query.card == true) {
        this.card();
      }

      if (this.formInline.keywords) {
        var parmas = {
          currentPage: this.page.currentPage,
          pageSize: this.page.pageSize,
          startTime: this.formInline.optionDate1,
          endTime: this.formInline.optionDate2,
          orderColume: this.page.orderColume,
          orderRule: this.page.orderRule,
          customKey: this.seleShift,
          customVal: this.formInline.keywords,
        };
        const res = await this.$axios.post(
          "/httpServe/alarm/getDataInfo",
          parmas,
          true
        );

        this.lists = res.data.content;
        this.page.total = res.data.total;
      } else {
        var parmas = {
          currentPage: this.page.currentPage,
          pageSize: this.page.pageSize,
          startTime: this.formInline.optionDate1,
          endTime: this.formInline.optionDate2,
          orderColume: this.page.orderColume,
          orderRule: this.page.orderRule,
          warningType: this.formInline.devicValue,
        };
        const res = await this.$axios.post(
          "/httpServe/alarm/getDataInfo",
          parmas,
          true
        );
        // console.log(res);
        this.lists = res.data.content;
        this.page.total = res.data.total;
      }
    },

    //导出
    async outreport() {
      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMzNzU1NjQsInVzZXJJZCI6MSwiaWF0IjoxNjMyNjU1NTY0fQ.OtDA-uF3NfP8xqj902kqxPu54UPgs8SYVY1nBTl1Ifnvoxw_-MPmcN_DKeYDQXHZfEizztITgMABrO0tBmvshA";
      let userString = decodeURIComponent(
        escape(window.atob(token2.split(".")[1]))
      );
      let groupId = JSON.parse(userString).userId;
      this.userId = groupId;
      if (this.$route.query.fl == true) {
        this.Day();
      } else if (this.$route.query.fl == false) {
        this.Mounth();
      }
      if (this.$route.query.cli == true) {
        this.MingTime();
      }
      if (this.$route.query.card == true) {
        this.card();
      }

      var parmas = {
        currentPage: this.page.currentPage,
        pageSize: this.page.total,
        startTime: this.formInline.optionDate1,
        endTime: this.formInline.optionDate2,
        warningType: this.formInline.devicValue,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        exportField: this.tradeSelections1,
      };
      console.log(parmas, "导出parmas");
      const res = await this.$axios.postExport(
        "/httpExport/alarm/exportExcel",
        parmas,
        true,
        this.userId
      );
      // console.log(res);
      const link = document.createElement("a");
      var fileName = "exportAlarm_" + Date.parse(new Date()) + ".xls";
      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    //配置项监听事件
    tableTitleTime(event) {},
  },
};
</script>

<style lang="scss" scoped>
.common-wrapper {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
}
.elRow {
  margin-bottom: 10px;
}
/* 设置高优先，以覆盖内联样式 Table组件固定列底部有一条白线
::v-deep  .el-table__fixed{
  height: 100vh !important;
}
::v-deep  .el-table__fixed-right{
  height: 100vh !important;
}*/
::v-deep .el-table__fixed::before {
  background-color: transparent !important; /*背景色完全透明*/
}
::v-deep .el-table__fixed-right::before {
  background-color: transparent !important; /*背景色完全透明*/
}
.placeOption {
  /* ---------------------------------------注意区分组件 */
  ::v-deep .el-input .el-input__inner::placeholder {
    color: #606266 !important;
  }

  /* 谷歌 */
  ::v-deep .el-input .el-input__inner::-webkit-input-placeholder {
    color: #606266 !important;
  }
  /* 火狐::-moz-placeholder */
  ::v-deep .el-input .el-input__inner::-moz-placeholder {
    color: #606266 !important;
    opacity: 1 !important; /*火狐默认有opacity:0.4; */
  }
  /*ie*/
  ::v-deep .el-input .el-input__inner:-ms-input-placeholder {
    color: #606266 !important;
  }
}

::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>